<template>
  <!-- <section class="social-login" v-if="show"> -->
  <section class="social-login">
    <div class="social-login__o">
      <span :class="color">o</span>
    </div>
    <div class="social-login__items">
      <a
        v-if="showGoogle"
        class="social-login__item"
        :class="[
          showLabel ? 'social-login__item--label' : '',
          size ? 'social-login__item--large' : '',
        ]"
        @click.prevent="loginWithGoogle()"
        :href="authUrl"
      >
        <img
          src="../../../assets/img/ico/ico-login-rounded-google.svg"
          alt="Login con Google"
        />
        <span v-if="showLabel">{{ $t("login.googleLogin", lang) }}</span>
      </a>
      <button
        v-if="showFacebook"
        class="social-login__item"
        :class="[
          showLabel ? 'social-login__item--label' : '',
          size ? 'social-login__item--large' : '',
        ]"
      >
        <img
          src="../../../assets/img/ico/ico-login-rounded-facebook.svg"
          alt="Login con Facebook"
        />
        <span v-if="showLabel">Entrar con Facebook</span>
      </button>
      <button
        v-if="showApple"
        class="social-login__item"
        :class="[
          showLabel ? 'social-login__item--label' : '',
          size ? 'social-login__item--large' : '',
        ]"
        @click.prevent="loginWithApple()"
      >
        <img
          src="../../../assets/img/ico/ico-login-rounded-apple.svg"
          alt="Login con Apple ID"
        />
        <span v-if="showLabel">{{ $t("login.appleLogin", lang) }}</span>
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "LoginSocial",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    showGoogle: {
      type: Boolean,
      default: true,
    },
    showFacebook: {
      type: Boolean,
      default: true,
    },
    showApple: {
      type: Boolean,
      default: true,
    },
    origin: {
      type: String,
      default: "signup",
    },
    color: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: false,
    },
    referralUserId: {
      type: String,
      default: "",
    },
    beforeCallback: {
      type: Function,
      default: (next) => {
        return next();
      },
    },
    forcedLang: {
      type: String,
      default: "",
    },
  },
  computed: {
    authUrl() {
      const queryParams = {
        origin: this.origin,
      };

      if (this.referralUserId) {
        queryParams.referralUserId = this.referralUserId;
      }

      const rawQueryParams = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");

      return `/auth/google?${rawQueryParams}`;
    },
    authUrlApple() {
      const queryParams = {
        origin: this.origin,
      };

      if (this.referralUserId) {
        queryParams.referralUserId = this.referralUserId;
      }

      const rawQueryParams = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");

      return `/auth/apple?${rawQueryParams}`;
    },
    lang() {
      return this.forcedLang || this.$i18n.locale;
    },
  },
  methods: {
    loginWithGoogle() {
      return this.beforeCallback(() => {
        window.location.href = this.authUrl;
      });
    },
    loginWithApple() {
      return this.beforeCallback(() => {
        window.location.href = this.authUrlApple;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
